import React from 'react'
import style from './button.module.scss';

export default props => {
	let className = [style.button];
	if (props.className) {
		props.className.split(' ').forEach(function(name){
			if (name in style)className.push(style[name]);
			else className.push(name);
		});
		className.filter(x => x);
	}
	let label = props.text||props.children;
	if (props.onClick) {
		var clickHandler = props.onClick;
	}
	return (
		<a onClick={clickHandler} target={props.target ? props.target : "_self"} href={ props.href } className={ className.join(' ') }>{ label }</a>
	)
}
