import React from 'react'
import logoStandard from '../images/danos_RGB_white_website.png';
import Link from './link';

export default (props) => {
	const useLogo = logoStandard;
	return (
		<>
			<Link to="/"><img style={{ transition: "all 0.25s ease-in" }} src={useLogo} alt="Danos Logo" /></Link>
		</>
	)
}