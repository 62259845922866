import React, { Component } from 'react'

class Icon extends Component {
	render() {
		let { width, height, background, foreground, icon, title, className } = this.props;
		// if (width === undefined)width = '100%';
		if (height === undefined) height = '100%';
		if (background === undefined) background = 'black';
		if (foreground === undefined) foreground = 'white';
		if (title === undefined) title = icon;
		const uniqueTitleID = [title, icon, className, Math.round(Math.random() * 100000)].join('_').replace(/[^a-z0-9]+/ig, '_');
		switch (icon) {
			case 'arrowLeft':
			case 'leftArrow':
			case 'arrow-left':
			case 'left-arrow':
				return (
					<svg width={width} height={height} className={className} viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<defs>
							<path id={`arrow-left-path-1-${uniqueTitleID}`} d="M0.375955545,10.69816 L10.7732462,0.37335379 C11.2745203,-0.124451263 12.0876863,-0.124451263 12.5889604,0.37335379 L13.8017547,1.57775513 C14.3024938,2.07502891 14.3030287,2.88044242 13.8038946,3.37877875 L5.56363383,11.5997344 L13.8033596,19.8212212 C14.3030287,20.3195576 14.3019588,21.1249711 13.8012197,21.6222449 L12.5884254,22.8266462 C12.0871513,23.3244513 11.2739853,23.3244513 10.7727112,22.8266462 L0.375955545,12.5013087 C-0.125318515,12.0035037 -0.125318515,11.1959651 0.375955545,10.69816 Z"></path>
						</defs>
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g className="background" transform="translate(4.000000, 4.000000)" fill={background}>
								<ellipse cx="26.5" cy="26.5" rx="26.5" ry="26"></ellipse>
							</g>
							<g transform="translate(17.000000, 19.000000)">
								<g transform="translate(4.800000, 0.400000)">
									<mask className="foreground" id={`arrow-left-mask-2-${uniqueTitleID}`} fill={foreground}>
										<use xlinkHref={`#arrow-left-path-1-${uniqueTitleID}`}></use>
									</mask>
									<g className="foreground" mask={`url(#arrow-left-mask-2-${uniqueTitleID})`} fill={foreground}>
										<g transform="translate(-4.800000, -0.400000)">
											<rect x="0" y="0" width="24" height="24"></rect>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'arrow-left-solid':
				return (
					<svg viewBox="0 0 448 512" width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
					</svg>
				)

			case 'arrowRight':
			case 'rightArrow':
			case 'arrow-right':
			case 'right-arrow':
				return (
					<svg width={width} height={height} className={className} viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<defs>
							<path id={`arrow-right-arrow-path-${uniqueTitleID}`} d="M13.8017403,12.5015978 L3.40454035,22.826528 C2.90309469,23.3244907 2.09012464,23.3244907 1.58873248,22.826528 L0.376071774,21.6222901 C-0.124517904,21.1251774 -0.125480885,20.3195011 0.373931816,19.8212196 L8.61389364,11.5999734 L0.373931816,3.37878043 C-0.125480885,2.88049894 -0.124517904,2.07482256 0.376071774,1.57770987 L1.58873248,0.373472045 C2.09017814,-0.124490682 2.90314819,-0.124490682 3.40454035,0.373472045 L13.8016868,10.6984022 C14.3031325,11.1963118 14.3031325,12.0036351 13.8017403,12.5015978 Z"></path>
						</defs>
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g className="background" transform="translate(4.000000, 4.000000)" fill={background}>
								<ellipse cx="26.5" cy="26.5" rx="26.5" ry="26"></ellipse>
							</g>
							<g transform="translate(21.000000, 19.000000)">
								<g transform="translate(4.800000, 0.400000)">
									<mask className="foreground" id={`arrow-right-mask-2-${uniqueTitleID}`} fill={foreground}>
										<use xlinkHref={`#arrow-right-arrow-path-${uniqueTitleID}`}></use>
									</mask>
									<g className="foreground" mask={`url(#arrow-right-mask-2-${uniqueTitleID})`} fill={foreground}>
										<g transform="translate(-4.800000, -0.400000)">
											<rect x="0" y="0" width="24" height="24"></rect>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'plus':
				return (
					<svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g transform="translate(1.000000, 1.000000)" stroke={foreground}>
								<g>
									<circle fill={background} cx="11" cy="11" r="11"></circle>
									<line stroke={foreground} x1="7.19526215" y1="6.75735931" x2="15.1952621" y2="14.7573593" strokeLinecap="round" transform="translate(11.195262, 10.757359) rotate(45.000000) translate(-11.195262, -10.757359) "></line>
									<line stroke={foreground} x1="15.1952621" y1="6.75735931" x2="7.19526215" y2="14.7573593" strokeLinecap="round" transform="translate(11.195262, 10.757359) rotate(45.000000) translate(-11.195262, -10.757359) "></line>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'plus-w-circle':
				return (
					<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
					</svg>
				)

			case 'minus':
				return (
					<svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g transform="translate(-278.000000, -261.000000)">
								<g transform="translate(279.000000, 262.000000)" stroke={foreground}>
									<line x1="15.1952621" y1="6.75735931" x2="7.19526215" y2="14.7573593" strokeLinecap="square" transform="translate(11.195262, 10.757359) rotate(45.000000) translate(-11.195262, -10.757359) "></line>
									<circle cx="11" cy="11" r="11"></circle>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'search':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
					</svg>
				)

			case 'bars':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
					</svg>
				)

			case 'facebook':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
					</svg>
				)

			case 'twitter':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
					</svg>
				)
			case 'x-twitter':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"></path>
					</svg>
				)

			case 'twitter-circle':
				return (
					<svg width={width} height={height} className={className} aria-labelledby={uniqueTitleID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z" />
					</svg>
				)

			case 'linkedin':
				return (
					<svg width={width} height={height} className={className} aria-labelledby={uniqueTitleID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
					</svg>
				)

			case 'envelope':
				return (
					<svg width={width} height={height} className={className} aria-labelledby={uniqueTitleID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/>
					</svg>
				)

			case 'youtube':
				return (
					<svg width={width} height={height} className={className} aria-labelledby={uniqueTitleID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
					</svg>
				)

			case 'gsuite':
				return (
					<svg width={width} height={height} className={className} aria-labelledby={uniqueTitleID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z" />
					</svg>
				)

			case 'instagram':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
					</svg>
				)

			case 'yelp':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M42.9 240.32l99.62 48.61c19.2 9.4 16.2 37.51-4.5 42.71L30.5 358.45a22.79 22.79 0 0 1-28.21-19.6 197.16 197.16 0 0 1 9-85.32 22.8 22.8 0 0 1 31.61-13.21zm44 239.25a199.45 199.45 0 0 0 79.42 32.11A22.78 22.78 0 0 0 192.94 490l3.9-110.82c.7-21.3-25.5-31.91-39.81-16.1l-74.21 82.4a22.82 22.82 0 0 0 4.09 34.09zm145.34-109.92l58.81 94a22.93 22.93 0 0 0 34 5.5 198.36 198.36 0 0 0 52.71-67.61A23 23 0 0 0 364.17 370l-105.42-34.26c-20.31-6.5-37.81 15.8-26.51 33.91zm148.33-132.23a197.44 197.44 0 0 0-50.41-69.31 22.85 22.85 0 0 0-34 4.4l-62 91.92c-11.9 17.7 4.7 40.61 25.2 34.71L366 268.63a23 23 0 0 0 14.61-31.21zM62.11 30.18a22.86 22.86 0 0 0-9.9 32l104.12 180.44c11.7 20.2 42.61 11.9 42.61-11.4V22.88a22.67 22.67 0 0 0-24.5-22.8 320.37 320.37 0 0 0-112.33 30.1z"></path>
					</svg>
				)

			case 'share':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M568.482 177.448L424.479 313.433C409.3 327.768 384 317.14 384 295.985v-71.963c-144.575.97-205.566 35.113-164.775 171.353 4.483 14.973-12.846 26.567-25.006 17.33C155.252 383.105 120 326.488 120 269.339c0-143.937 117.599-172.5 264-173.312V24.012c0-21.174 25.317-31.768 40.479-17.448l144.003 135.988c10.02 9.463 10.028 25.425 0 34.896zM384 379.128V448H64V128h50.916a11.99 11.99 0 0 0 8.648-3.693c14.953-15.568 32.237-27.89 51.014-37.676C185.708 80.83 181.584 64 169.033 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48v-88.806c0-8.288-8.197-14.066-16.011-11.302a71.83 71.83 0 0 1-34.189 3.377c-7.27-1.046-13.8 4.514-13.8 11.859z"></path>
					</svg>
				)

			case 'close':
				return (
					<svg width={width} height={height} className={className} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
						<title id={uniqueTitleID}>{title}</title>
						<path d="M9,8 L54,52 M52,8 L7,52" stroke={foreground} strokeWidth="3" />
					</svg>
				)

			case 'arrowDownCircle':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"></path>
					</svg>
				)

			case 'chevronDown':
			case 'downChevron':
			case 'chevron-down':
			case 'down-chevron':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
					</svg>
				)

			case 'chevronUp':
			case 'upChevron':
			case 'chevron-up':
			case 'up-chevron':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>
					</svg>
				)

			case 'arrowWithStemRight':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
					</svg>
				)

			case 'phone':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
					</svg>
				)

			case 'location':
			case 'mapMarker':
			case 'map-marker':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
					</svg>
				)

			case 'restaurants':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167 106" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g id="Mock-Ups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="Neighborhoods" transform="translate(-321.000000, -1979.000000)">
								<g id="Neighborhood-Feature/Left" transform="translate(0.000000, 726.000000)">
									<g id="Left" transform="translate(0.000000, 833.000000)">
										<g id="BCVB_icons_restaurants" transform="translate(321.000000, 420.000000)">
											<path d="M134.092322,106 C128.183296,105.710167 125.431808,102.131601 127.949214,93.0642495 C127.990794,92.9146913 128.026728,92.7620388 128.053935,92.6093864 C131.264346,74.4700429 132.902406,57.0346543 131.527175,40.9478678 C131.489702,40.5058976 131.392167,40.070116 131.218659,39.6621832 C130.588795,38.182073 129.15145,36.5235244 127.339369,34.782461 C126.336308,33.8191001 125.632009,32.5736415 125.375341,31.2033792 C124.209552,24.9848536 126.016499,11.5344167 127.292656,0.095795038 C127.304976,-0.0109585543 127.460517,-0.00322278673 127.463084,0.104046523 L128.085248,28.1229966 C128.085248,28.7047263 129.930183,28.7047263 129.930183,28.1229966 L131.177079,0.082902092 C131.180672,-0.0279772429 131.344427,-0.0274615251 131.34802,0.0834178099 L132.210427,28.1229966 C132.210427,28.8016813 133.846947,28.8016813 133.846947,28.1229966 L134.492212,0.0834178099 C134.495292,-0.0274615251 134.659046,-0.0279772429 134.663153,0.082902092 L135.691881,28.1229966 C135.691368,28.7047263 137.75447,28.7047263 137.75447,28.1229966 L138.376635,0.104046523 C138.379202,-0.00322278673 138.535256,-0.0109585543 138.547063,0.095795038 C139.823733,11.5344167 141.630167,24.9848536 140.464378,31.2033792 C140.207709,32.5736415 139.50341,33.8191001 138.500349,34.782461 C136.688269,36.5235244 135.250924,38.182073 134.621573,39.6621832 C134.447551,40.070116 134.334104,40.5043504 134.312543,40.9478678 C133.519951,56.974831 136.791449,74.3782451 139.962847,92.6093864 C139.989541,92.7620388 140.025475,92.9146913 140.067055,93.0642495 C142.583948,102.131601 139.832973,105.710167 133.923947,106 L134.092322,106" id="Fill-1" fill="#128339"></path>
											<path d="M128.96072,27 L129,28.6000709 C129,29.1333097 127.078014,29.1333097 127.078014,28.6000709 L127,27 C127.000546,27.3963836 128.961266,27.40312 128.96072,27" id="Fill-2" fill="#A0CC94"></path>
											<path d="M131,26 L131.060606,28.4145329 C131.060606,29.1951557 132,29.1951557 132,28.4145329 L131.966061,26 C131.966061,26.6778547 131,26.6866782 131,26" id="Fill-3" fill="#A0CC94"></path>
											<path d="M137.981751,26 L138,28.4145329 C138,29.1951557 136.053268,29.1951557 136.053268,28.4145329 L136,26 C136,26.5859862 137.981751,26.58391 137.981751,26" id="Fill-4" fill="#A0CC94"></path>
											<path d="M130.998797,69 C128.49028,88.7053205 125.301369,100.613069 132,99.9756297 C127.509485,100.327528 129.17149,85.944424 130.998797,69" id="Fill-5" fill="#A0CC94"></path>
											<path d="M10.7548861,102.218198 C10.7548861,107.260601 0.507136365,107.260601 0.507136365,102.218198 C0.174776913,94.0367789 1.09964622,79.2771712 3.59629877,56.2882637 C3.64822994,55.8130608 3.6690024,55.33065 3.65713242,54.852358 C3.19222485,35.9770707 2.1526124,18.1062482 0.0066188574,2.17124688 C-0.0131644433,2.02348606 0.0110701001,1.87109162 0.0837737302,1.74238009 C1.21735686,-0.269638418 3.16403365,-0.427696167 5.2051757,0.698787074 C6.45646947,1.38919568 7.47382571,2.45698646 8.2181724,3.71063669 C13.1333335,11.9883321 13.5814252,32.2475256 13.999842,52.58755 C14.0077554,52.9875854 13.7174354,53.320176 13.3356177,53.3618786 C12.0150824,53.5055206 10.7197708,54.4559265 9.45017745,56.25068 C7.03265811,60.8806908 8.29384353,72.9172775 10.5684285,86.9926547 C11.2489741,93.0297399 11.5284132,98.4438614 10.7548861,102.218198" id="Fill-6" fill="#5D8E89"></path>
											<path d="M13,50.6724352 C13.0026908,51.4340717 12.6284411,52.1308107 12.0404631,52.4791802 C11.4533869,52.8265113 10.8140059,53.1525561 10.8140059,52.9235979 C10.8140059,52.157808 9.64842073,20.6887759 9.00047259,13.1066772 C8.99055272,12.9872659 9.13980173,12.9571535 9.16595413,13.0729306 C11.6788386,24.0707117 12.9562478,36.604226 13,50.6724352" id="Fill-7" fill="#A0CC94"></path>
											<path d="M156.539385,105.994786 C155.839602,106.023746 155.137707,105.932729 154.471725,105.705187 C151.695304,104.7583 149.998926,101.880406 149.607047,99.0273352 C149.214113,96.173747 149.876398,93.2963703 150.467384,90.4763963 C153.53428,75.8557647 154.799171,60.8741681 154.225613,45.9587657 C154.171215,44.5490373 154.088297,43.0927661 153.443441,41.8314574 C152.574654,40.1326467 150.838665,39.061129 149.450718,37.733109 C145.751641,34.1927566 144.565443,28.7126054 145.135832,23.6787476 C145.664498,19.0146462 147.543084,14.749261 149.355125,10.4580187 C150.558752,7.60805053 153.255952,3.4967736 156.974042,4.05063242 C158.018171,4.20680923 158.96565,4.76118519 159.749407,5.45518942 C161.602643,7.09556306 162.597654,9.44545518 163.501826,11.7265674 C165.118984,15.8052644 166.643718,19.9930784 166.948982,24.358789 C167.255302,28.7250168 166.20378,33.3508498 163.209766,36.5995343 C161.627993,38.316962 159.522307,39.6542906 158.521486,41.7487147 C157.789487,43.28204 157.747764,45.0325648 157.718189,46.7246527 C157.582985,54.6157186 157.44831,62.5279875 158.245798,70.3813021 C159.130957,79.0987606 161.156894,87.6677994 162.266512,96.359918 C162.545897,98.5448419 162.742364,100.87922 161.724642,102.843324 C160.756566,104.711757 158.661443,105.907389 156.539385,105.994786" id="Fill-8" fill="#36455F"></path>
											<path d="M161.394614,11.1428731 C164.879886,21.0747612 167.105741,30.4941815 161.982987,36.9234181 C161.843266,37.0989712 161.577363,36.9475961 161.631027,36.7226359 C163.393243,29.3699061 162.869171,20.7047332 161.005428,11.2700703 C160.952247,11.0020102 161.305174,10.8874277 161.394614,11.1428731" id="Fill-9" fill="#166D8D"></path>
											<path d="M123,52.4997397 C123,80.3902277 100.390228,103 72.5002603,103 C44.6092517,103 22,80.3902277 22,52.4997397 C22,24.6097723 44.6092517,2 72.5002603,2 C100.390228,2 123,24.6097723 123,52.4997397" id="Fill-10" fill="#166D8D"></path>
											<path d="M123,52.4997397 C123,80.3902277 100.390228,103 72.5002603,103 C44.6092517,103 22,80.3902277 22,52.4997397 C22,24.6097723 44.6092517,2 72.5002603,2 C100.390228,2 123,24.6097723 123,52.4997397 Z" id="Stroke-11" stroke="#FFFFFE" strokeWidth="1.19"></path>
											<path d="M111,52.9997425 C111,73.9868886 93.7625101,91 72.5002609,91 C51.2369682,91 34,73.9868886 34,52.9997425 C34,32.0131114 51.2369682,15 72.5002609,15 C93.7625101,15 111,32.0131114 111,52.9997425" id="Fill-12" fill="#166D8D"></path>
											<path d="M111,52.9997425 C111,73.9868886 93.7625101,91 72.5002609,91 C51.2369682,91 34,73.9868886 34,52.9997425 C34,32.0131114 51.2369682,15 72.5002609,15 C93.7625101,15 111,32.0131114 111,52.9997425 Z" id="Stroke-13" stroke="#FFFFFE" strokeWidth="1.447"></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'shops':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171 127" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g id="Mock-Ups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="Neighborhoods" transform="translate(-312.000000, -2257.000000)">
								<g id="Neighborhood-Feature/Left" transform="translate(0.000000, 726.000000)">
									<g id="Left" transform="translate(0.000000, 833.000000)">
										<g id="BCVB_icons_shops" transform="translate(314.000000, 700.000000)">
											<polyline id="Fill-1" fill="#A0CC94" points="154.226131 48 145 119 167 119 154.226131 48"></polyline>
											<polygon id="Stroke-2" stroke="#FFFFFE" strokeWidth="3.384" strokeLinecap="round" strokeLinejoin="round" points="154.226131 48 145 119 167 119"></polygon>
											<polyline id="Fill-3" fill="#5D8E89" points="155 48 105.034337 48 92 119 145.585742 119"></polyline>
											<polyline id="Stroke-4" stroke="#FFFFFE" strokeWidth="3.384" points="155 48 105.034337 48 92 119 145.585742 119"></polyline>
											<path d="M117,44 C117,28 140,28 140,44" id="Stroke-5" stroke="#5D8E89" strokeWidth="3.384" strokeLinecap="round" strokeLinejoin="round"></path>
											<polyline id="Fill-6" fill="#36455F" points="87.419158 22 74 123 106 123 87.419158 22"></polyline>
											<polygon id="Stroke-7" stroke="#FFFFFE" strokeWidth="3.384" strokeLinecap="round" strokeLinejoin="round" points="87.419158 22 74 123 106 123"></polygon>
											<polyline id="Fill-8" fill="#166D8D" points="88 22 18.2068347 22 0 123 74.8505197 123"></polyline>
											<polyline id="Stroke-9" stroke="#FFFFFE" strokeWidth="3.384" strokeLinecap="round" strokeLinejoin="round" points="88 22 18.2068347 22 0 123 74.8505197 123"></polyline>
											<path d="M36,20 C36,-6.66666667 68,-6.66666667 68,20" id="Stroke-10" stroke="#166D8D" strokeWidth="3.384" strokeLinecap="round" strokeLinejoin="round"></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'parks':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 123" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g id="Mock-Ups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="Neighborhoods" transform="translate(-345.000000, -1683.000000)">
								<g id="Neighborhood-Feature/Left" transform="translate(0.000000, 726.000000)">
									<g id="Left" transform="translate(0.000000, 833.000000)">
										<g id="BCVB_icons_parks" transform="translate(346.000000, 126.000000)">
											<path d="M36,63.0002871 C36,49.745069 27.9413097,39 18,39 C8.05869031,39 0,49.745069 0,63.0002871 C0,76.254931 8.05869031,87 18,87 C27.9413097,87 36,76.254931 36,63.0002871" id="Fill-1" fill="#0C5C35"></path>
											<path d="M36,63.0002871 C36,49.745069 27.9413097,39 18,39 C8.05869031,39 0,49.745069 0,63.0002871 C0,76.254931 8.05869031,87 18,87 C27.9413097,87 36,76.254931 36,63.0002871 Z" id="Stroke-2" stroke="#FFFFFE" strokeWidth="1.742" strokeLinecap="round"></path>
											<path d="M18.8568096,112 C18.8568096,109.540808 18.8568096,92.6610084 18.8568096,79.0546505 C18.8568096,69.6805753 18.8568096,61.8391716 18.8568096,61.8391716 L28,52.9504707 M8,71.1542761 L18.8568096,78.6276799 M18.8568096,61.8391716 L18.8568096,49" id="Stroke-3" stroke="#A0CC94" strokeWidth="2.177" strokeLinecap="round"></path>
											<path d="M2,118 C39.4453393,104.734403 75.1081936,104.599102 109,118" id="Stroke-4" stroke="#128339" strokeWidth="3.981" strokeLinecap="round"></path>
											<path d="M97,53.5002878 C97,38.3124706 87.8215018,26 76.4997139,26 C65.1784982,26 56,38.3124706 56,53.5002878 C56,68.6881051 65.1784982,81 76.4997139,81 C87.8215018,81 97,68.6881051 97,53.5002878" id="Fill-5" fill="#128339"></path>
											<path d="M97,53.5002878 C97,38.3124706 87.8215018,26 76.4997139,26 C65.1784982,26 56,38.3124706 56,53.5002878 C56,68.6881051 65.1784982,81 76.4997139,81 C87.8215018,81 97,68.6881051 97,53.5002878 Z" id="Stroke-6" stroke="#FFFFFE" strokeWidth="1.991" strokeLinecap="round"></path>
											<path d="M69,46.9997155 C69,72.9574912 58.7026871,94 45.9997182,94 C33.2973129,94 23,72.9574912 23,46.9997155 C23,21.0425088 33.2973129,0 45.9997182,0 C58.7026871,0 69,21.0425088 69,46.9997155" id="Fill-7" fill="#A0CC94"></path>
											<path d="M69,46.9997155 C69,72.9574912 58.7026871,94 45.9997182,94 C33.2973129,94 23,72.9574912 23,46.9997155 C23,21.0425088 33.2973129,0 45.9997182,0 C58.7026871,0 69,21.0425088 69,46.9997155 Z" id="Stroke-8" stroke="#FFFFFE" strokeWidth="2.488" strokeLinecap="round"></path>
											<path d="M79.826482,117.298195 C79.826482,115.961266 79.826482,88.9649913 79.826482,73.5295897 C79.826482,62.7577615 79.826482,53.7470309 79.826482,53.7470309 L90,43.5333491 M70.7825346,64.4510149 L79.826482,73.0392874 M79.826482,53.7470309 L79.826482,38.9940617 M47.6084487,119 L47.6084487,18 M47.6084487,53.9055239 L62.6659823,38.7899591 M47.6084487,73.5295897 L38,63.9607126" id="Stroke-9" stroke="#0C5C35" strokeWidth="2.488" strokeLinecap="round"></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'rentals':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 122" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g id="Mock-Ups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="Neighborhoods" transform="translate(-1106.000000, -4256.000000)">
								<g id="Neighborhood-Feature/Right" transform="translate(0.000000, 2462.000000)">
									<g id="Right">
										<g id="Group-3-Copy" transform="translate(95.000000, 833.000000)">
											<g id="BCVB_icons_hotels" transform="translate(1011.000000, 961.000000)">
												<polygon id="Fill-1" fill="#36455F" points="43 119 90 119 90 0 43 0"></polygon>
												<polygon id="Fill-3" fill="#166D8D" points="92 118 131 118 131 34 92 34"></polygon>
												<polygon id="Fill-5" fill="#5D8E89" points="0 118 39 118 39 34 0 34"></polygon>
												<polygon id="Fill-7" fill="#FFFFFE" points="55 26 61 26 61 11 55 11"></polygon>
												<polygon id="Fill-9" fill="#FFFFFE" points="72 26 78 26 78 11 72 11"></polygon>
												<polygon id="Fill-11" fill="#FFFFFE" points="55 48 61 48 61 33 55 33"></polygon>
												<polygon id="Fill-13" fill="#FFFFFE" points="72 48 78 48 78 33 72 33"></polygon>
												<polygon id="Fill-15" fill="#FFFFFE" points="55 69 61 69 61 54 55 54"></polygon>
												<polygon id="Fill-17" fill="#FFFFFE" points="72 69 78 69 78 54 72 54"></polygon>
												<polygon id="Fill-19" fill="#FFFFFE" points="101 61 107 61 107 46 101 46"></polygon>
												<polygon id="Fill-21" fill="#FFFFFE" points="117 61 123 61 123 46 117 46"></polygon>
												<polygon id="Fill-23" fill="#FFFFFE" points="101 84 107 84 107 69 101 69"></polygon>
												<polygon id="Fill-25" fill="#FFFFFE" points="117 84 123 84 123 69 117 69"></polygon>
												<polygon id="Fill-27" fill="#FFFFFE" points="101 106 107 106 107 91 101 91"></polygon>
												<polygon id="Fill-29" fill="#FFFFFE" points="117 106 123 106 123 91 117 91"></polygon>
												<polygon id="Fill-31" fill="#FFFFFE" points="9 61 15 61 15 46 9 46"></polygon>
												<polygon id="Fill-33" fill="#FFFFFE" points="25 61 31 61 31 46 25 46"></polygon>
												<polygon id="Fill-35" fill="#FFFFFE" points="9 84 15 84 15 69 9 69"></polygon>
												<polygon id="Fill-37" fill="#FFFFFE" points="25 84 31 84 31 69 25 69"></polygon>
												<polygon id="Fill-39" fill="#FFFFFE" points="9 106 15 106 15 91 9 91"></polygon>
												<polygon id="Fill-41" fill="#FFFFFE" points="25 106 31 106 31 91 25 91"></polygon>
												<polygon id="Fill-43" fill="#FFFFFE" points="55 93 61 93 61 78 55 78"></polygon>
												<polygon id="Fill-45" fill="#FFFFFE" points="72 93 78 93 78 78 72 78"></polygon>
												<polygon id="Stroke-46" stroke="#FFFFFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="57 120.131501 57 104 76.1614062 104 76.1614062 120.131501"></polygon>
												<line x1="66.5" y1="118" x2="66.5" y2="102" id="Fill-47" fill="#FFFFFE"></line>
												<line x1="66.5" y1="120" x2="66.5" y2="104" id="Stroke-48" stroke="#FFFFFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
												<polygon id="Fill-1" fill="#128339" points="136 118 166 118 166 95 136 95"></polygon>
												<path d="M150.242457,79.2761023 L130.431059,95.8799189 C129.576328,96.5962211 130.077937,98 131.188602,98 L170.811398,98 C171.922063,98 172.423672,96.5962211 171.568941,95.8799189 L151.757543,79.2761023 C151.318239,78.9079659 150.681761,78.9079659 150.242457,79.2761023" id="Fill-2" fill="#0C5C35"></path>
												<path d="M150.242457,79.2761023 L130.431059,95.8799189 C129.576328,96.5962211 130.077937,98 131.188602,98 L170.811398,98 C171.922063,98 172.423672,96.5962211 171.568941,95.8799189 L151.757543,79.2761023 C151.318239,78.9079659 150.681761,78.9079659 150.242457,79.2761023 Z" id="Stroke-3" stroke="#FFFFFE" strokeWidth="2.295" strokeLinejoin="round"></path>
												<path d="M153.822985,92.0709786 L151.000264,95 L148.177376,92.0709786 C145.656485,89.455309 147.452884,84.983526 151.017861,85 C154.565744,85.0165653 156.331641,89.4678292 153.822985,92.0709786" id="Fill-4" fill="#128339"></path>
												<path d="M153.822985,92.0709786 L151.000264,95 L148.177376,92.0709786 C145.656485,89.455309 147.452884,84.983526 151.017861,85 C154.565744,85.0165653 156.331641,89.4678292 153.822985,92.0709786 Z" id="Stroke-5" stroke="#FFFFFE" strokeWidth="1.5"></path>
												<path d="M152,88.9999537 C152,89.5523011 151.55228,90 151,90 C150.44772,90 150,89.5523011 150,88.9999537 C150,88.4476989 150.44772,88 151,88 C151.55228,88 152,88.4476989 152,88.9999537" id="Fill-6" fill="#A0CC94"></path>
												<path d="M152,88.9999537 C152,89.5523011 151.55228,90 151,90 C150.44772,90 150,89.5523011 150,88.9999537 C150,88.4476989 150.44772,88 151,88 C151.55228,88 152,88.4476989 152,88.9999537 Z" id="Stroke-7" stroke="#FFFFFE"></path>
												<polygon id="Stroke-8" stroke="#FFFFFE" strokeWidth="2.295" strokeLinejoin="round" points="147 119 154 119 154 109 147 109"></polygon>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)

			case 'calendar':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
					</svg>
				)

			case 'at':
			case '@':
			case 'atSymbol':
				return (
					<svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<path d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z"></path>
					</svg>
				)

			case 'loading':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: "none", display: "block", shapeRendering: "auto", animationPlayState: "running", animationDelay: "0s" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" r="18.0745" fill="none" stroke="#5a813d" strokeWidth="1" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							<animate attributeName="r" repeatCount="indefinite" dur="1.923076923076923s" values="0;42" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.9615384615384615s" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							</animate>
							<animate attributeName="opacity" repeatCount="indefinite" dur="1.923076923076923s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.9615384615384615s" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							</animate>
						</circle>
						<circle cx="50" cy="50" r="37.4829" fill="none" stroke="#00769f" strokeWidth="1" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							<animate attributeName="r" repeatCount="indefinite" dur="1.923076923076923s" values="0;42" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							</animate>
							<animate attributeName="opacity" repeatCount="indefinite" dur="1.923076923076923s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" style={{ animationPlayState: "running", animationDelay: "0s" }}>
							</animate>
						</circle>
					</svg>
				)
			case 'diamond':
			case 'mastheadPager':
				return (
					<svg width={width} height={height} className={className} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-labelledby={uniqueTitleID}>
						<title id={uniqueTitleID}>{title}</title>
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g transform="translate(-1284.000000, -437.000000)" fill={foreground}>
								<rect transform="translate(1294.000000, 447.000000) rotate(45.000000) translate(-1294.000000, -447.000000) " x="1287" y="440" width="14" height="14"></rect>
							</g>
						</g>
					</svg>
				)
			default:
				return (
					<div>{icon} is not found.</div>
				)
		}
	}
}

export default Icon;
