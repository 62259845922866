import React, {Component} from 'react';
import EventCard from './eventCard';
import ListingCard from './listingCard';
import StoryCard from './storyCard';
import PortfolioCard from './portfolioCard';
import TrainingCard from './trainingCard';
import PostCard from './postCard';

class Card extends Component {

	render() {
		const type = this.props.type;
		switch (type) {
			case 'Post':
				return (
					<PostCard className={this.props.className} data={this.props.data} />
				)
			case 'Report':
				return (
					<PostCard className={this.props.className} data={this.props.data} />
				)
			case 'Release':
				return (
					<PostCard className={this.props.className} data={this.props.data} />
				)
			case 'Event':
				return (
					<EventCard onReadMoreClick={this.props.onReadMoreClick} className={this.props.className} data={this.props.data} />
				)
			case 'Story':
				return (
					<StoryCard className={this.props.className} data={this.props.data} />
				)
			case 'Portfolio':
				return (
					<PortfolioCard className={this.props.className} data={this.props.data} />
				)
			case 'Training':
				return (
					<TrainingCard className={this.props.className} index={this.props.index} data={this.props.data} />
				)
			default:
				return (
					<ListingCard className={this.props.className} data={this.props.data} />
				)
		}
	}
}

export default Card;
