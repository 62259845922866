import React, {Component} from 'react';
import style from './card.module.scss';
import Background from 'components/background';
import Link from 'components/link';

class ListingCard extends Component {

	render() {
		const data = this.props.data;

		var title = ''
		if(data.title) {
			title = data.title
		} else if (data.name) {
			title = data.name
		} else if (data.headline) {
			title = data.headline
		}
		var image = ''
		if(data.photo) {
			image = data.photo
		} else if (data.featuredImage) {
			image = data.featuredImage
		} else {
			image = null;
		}

		const link = data.uri|| '/';

		var description = '';
		if(data.intro) {
			description = data.intro
		} else if (data.description) {
			description = data.description
		} else if (data.excerpt) {
			description = data.excerpt;
		}
		const maxLength = 37;
		if (description) {
			var wordCount = description.split(" ").length;
			description = description.split(" ").splice(0,maxLength).join(" ");
			if (wordCount > maxLength) {
				description += '...';
			}
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function(name){
				if (name in style)className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}
		return (
			<div datalayer="Listing Card" className={ style.container }><Link className={style.linkContainer} href={link} aria-label={`Read more about ${title}`}><div className={ className.join(' ') }>
				<div className={style.image}>
					<Background image={image} style={ {minHeight: '100%'} }>
					</Background>
				</div>
				<div className={style.title}>{title}</div>
				{description && <div className={style.description}>{description}</div>}
				<div className={style.buttons}>
					<div className={ ['primary', 'button', style.button].join(' ') }>Read More</div>
				</div>
			</div></Link></div>
		)
	}
}

export default ListingCard;
