import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby';
// Import neccessary site components, try to maintain alphabetical order
import BlogFeed from 'components/blog';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class NewsFeedController extends Component {
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}press/release/all/?_join=true`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						status: STATUS.LOADED,
						data: data,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allPressRelease && props.data.allPressRelease.edges.length) {
			props.data.allPressRelease.edges.forEach(post => {
				initialData.push(post.node);
			});
		}
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<BlogFeed title="News Reports" backText="View all news reports" backLink="/news/" data={this.state.data}/>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allPressRelease(sort: {order: DESC, fields: showDate}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	_model
			        id
			        photo
			        headline
			        permalink
			        uri
			        excerpt
			        showDate
			      }
			    }
			  }
			}
		`}
		render={data => (<NewsFeedController data={data} {...props} />)}
	/>
)