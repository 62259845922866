import React, { Component } from 'react';
import style from './eventCard.module.scss';
import Link from 'components/link';
import Plus from './plus';

class EventCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allDay: false,
			expanded: false
		}
	}

	render() {
		const data = this.props.data;
		var title = ''
		if (data.title) {
			title = data.title
		} else if (data.name) {
			title = data.name
		} else {
			title = 'Event Name'
		}
		// const link = data.uri || '/events/';
		var description = '';
		if (data.summary) {
			description = data.summary;
		} else if (data.description) {
			description = data.description;
		} else {
			description = null;
		}

		const maxLength = 26;
		if (description) {
			var wordCount = description.split(" ").length;
			description = description.split(" ").splice(0, maxLength).join(" ");
			if (wordCount > maxLength) {
				description += '...';
			}
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function (name) {
				if (name in style) className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}

		let day = data.startDate ?
			new Date(data.startDate).toLocaleDateString('en-US', { weekday: 'long' }) :
			new Date(data.showDate).toLocaleDateString('en-US', { weekday: 'long' })

		let date = data.startDate ?
			new Date(data.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) :
			new Date(data.showDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })

		return (
			<div className={style.container}>
				<div datalayer="Event Card" className={`${className.join(' ')} ${this.state.expanded ? style.expanded : ""}`}>
					<div className={style.title}>{title}</div>
					<div className={`${style.dates} grid-x grid-margin-x`}>
						{/* <div className="cell small-12">
							{ day }{!this.state.expanded ? <br /> : ","} { date }
						</div> */}
						<div className="cell small-12">
							{data.time}
						</div>
					</div>
					{!this.state.expanded && <Plus onClick={() => this.setState({ expanded: true })} />}
					<div className={style.description}>{ description } <br /><Link to={data.uri}>Read More</Link></div>
				</div>
			</div>
		)
	}
}

export default EventCard;
