import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	EmailShareButton,
} from 'react-share';

import style from './share.module.scss';

class Share extends Component {
	constructor(props) {
		super(props);
		let displayClipboard = false;
		try {
			displayClipboard = (navigator && navigator.clipboard);
		}
		catch (e) {
			displayClipboard = false;
		}
		this.state = {
			copyMessage: false,
			copyParentBorderBlock: false,
			displayClipboard: displayClipboard,
		}
		this.socialConfig = this.props.socialConfig;
		this.tags = this.props.tags;
	}

	copyToClipboard = (e) => {
		navigator.clipboard.writeText(this.socialConfig.config.url)
			.then(() => {
				this.setState({
					copyMessage: true,
					copyParentBorderBlock: true,
				});
				setTimeout(
					function() {
						this.setState({copyMessage: false});
						setTimeout(function(){
							this.setState({copyParentBorderBlock: false});
						}.bind(this), 125);
					}
					.bind(this),
					3500
				);
			})
			.catch((err) => {
				alert(err);
			})
		;
	};

	render() {
		let { className, buttonClass, iconClass } = this.props;
		className = (className||'').split(' ');
		className.push(style.postSocial);
		className = className.filter((item) => item);

		buttonClass = (buttonClass||'').split(' ');
		buttonClass.push(style.shareButton);
		buttonClass = buttonClass.filter((item) => item);

		iconClass = (iconClass||'').split(' ');
		iconClass.push(style.icon);
		iconClass = iconClass.filter((item) => item);
		return (
			<div className={className.join(' ')}>
				<FacebookShareButton url={this.socialConfig.config.url} className={`${buttonClass.join(' ')} is-outlined is-rounded facebook`} >
					<span className={iconClass.join(' ')}>
						<Icon icon="facebook" />
					</span>
					<span className={style.text}>Facebook</span>
				</FacebookShareButton>
				<TwitterShareButton url={this.socialConfig.config.url} className={`${buttonClass.join(' ')} is-outlined is-rounded twitter`} title={this.socialConfig.config.title} via={this.socialConfig.twitterHandle.split('@').join('')} hashtags={this.tags} >
					<span className={iconClass.join(' ')}>
						<Icon icon="twitter" />
					</span>
					<span className={style.text}>Twitter</span>
				</TwitterShareButton>
				<LinkedinShareButton url={this.socialConfig.config.url} summary={this.socialConfig.config.description} source="Danos" className={`${buttonClass.join(' ')} is-outlined is-rounded linkedin`} title={this.socialConfig.config.title}>
					<span className={iconClass.join(' ')}>
						<Icon icon="linkedin" />
					</span>
					<span className={style.text}>LinkedIn</span>
				</LinkedinShareButton>
				<EmailShareButton url={this.socialConfig.config.url} className={`${buttonClass.join(' ')} is-outlined is-rounded envelope`} subject={`Danos.com article - ${this.socialConfig.config.title}`}>
					<span className={iconClass.join(' ')}>
						<Icon icon="envelope" />
					</span>
					<span className={style.text}>Email</span>
				</EmailShareButton>
				{ this.state.displayClipboard && <button onClick={this.copyToClipboard} aria-label="copy" className={ buttonClass.join(' ') }>
					<span className={ [iconClass.join(' '), (this.state.copyParentBorderBlock ? style.copySuccessParent : '')].join(' ') }>
						<Icon icon="share" />
						<div aria-hidden="true" className={`${style.copySuccess} ${this.state.copyMessage ? style.active : ''}`}>Copied to clipboard!</div>
					</span>
					<span className={style.text}>Share</span>
				</button> }
			</div>
		)
	}
}

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};


export default Share;