import React from 'react'
// import { useStaticQuery, graphql } from "gatsby"
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import '../styles/blurup.scss';

// import Img from "gatsby-image"

import { buildURL } from 'react-imgix';


/*
.ls-blur-up-is-loading, .lazyload:not([src]) {
	visibility: hidden;
}
.ls-blur-up-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
}

.ls-blur-up-img {
	filter: blur(10px);
	opacity: 1;
	transition: opacity 10000ms, filter 15000ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded {
	opacity: 0;
	filter: blur(5px);
}
 */

const Image = (props) => {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		allFile( filter: {internal: {mediaType: { regex: "ima?ge?s?/" } } } ) {
	// 			nodes {
	// 				relativePath
	// 				childImageSharp {
	// 					fluid(quality: 90, maxWidth: 1920) {
	// 						...GatsbyImageSharpFluid_withWebp
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `);
	const domain = 'https://danos.imgix.net';
	let imagePath = (props.src ? props.src.replace(/^\//, '') : 'images/Danos-Default-Placeholder-v2.jpg');
	if (imagePath.indexOf('/') === -1)imagePath = `images/${imagePath}`;
	imagePath = `${domain}/${imagePath}`;

	const alt = props.alt||'';
	const className = (props.className||'').split(' ');
	const paddingBottom = props.imagePadding || `${2/3*100}%`
	const sizes = props.sizes||[
		480,
		960,
		1000,
	];

	let fit = props.fit||null;

	let height = props.height||null;

	let width = props.width||1920;

	let lowQualOptions = {
		w: width*.05,
		auto: 'compress,format',
	};

	let fallBackOptions = {
		w: width,
		auto: 'compress',
	};

	if (fit && height) {
		lowQualOptions['h'] = fit;
		lowQualOptions['fit'] = fit;
		fallBackOptions['h'] = fit;
		fallBackOptions['fit'] = fit;
	}

	const lowQuality = buildURL(imagePath, lowQualOptions);

	const fallback = buildURL(imagePath, fallBackOptions);
	const srcset = [];
	sizes.forEach(function(size){
		let srcsetOptions = {
			w: size,
			auto: 'compress,format',
		};
		if (fit && height) {
			srcsetOptions['h'] = height;
			srcsetOptions['fit'] = fit;
		}
		srcset.push(`${buildURL(imagePath, srcsetOptions)} ${size}w`);
	});
	const srcsetWebP = [];
	sizes.forEach(function(size){
		let srcsetWebPOptions = {
			w: size,
			auto: 'compress',
			format: 'webp',
		}
		if (fit && height) {
			srcsetWebPOptions['h'] = height;
			srcsetWebPOptions['fit'] = fit;
		}
		srcsetWebP.push(`${buildURL(imagePath, srcsetWebPOptions)} ${size}w`);
	});
	const sizesAttribute = `(max-width: ${sizes[sizes.length-1]}px) 100vw, ${sizes[sizes.length-1]}px`;

	/*
	srcSet: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
	const match = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => imagePath === relativePath)
	), [ data, imagePath ]);
	const placeholder = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => 'Explore-Brookhaven-Georgia-Placeholder-Icon.jpg' === relativePath)
	), [ data ]);
	const imageData = [match ? match.childImageSharp.fluid : placeholder.childImageSharp.fluid];
	const alt = props.alt||'';
	const className = props.className||'';
	return <Img className={className} fluid={imageData} alt={alt} />
	*/
	className.push('lazyload');

	return (
		<div style={{
			position: 'relative',
			overflow: 'hidden',
		}}>
			<div aria-hidden="true" style={{
				width: '100%',
				paddingBottom: paddingBottom,
			}}/>
			<picture>
				<source
					type="image/webp"
					srcSet={srcsetWebP.join(', ')}
					sizes={sizesAttribute}
				/>
				<source
					srcSet={srcset.join(', ')}
					sizes={sizesAttribute}
				/>
				<img
					className={className.join(' ')}
					alt={alt}
					data-src={fallback}
					data-srcset={srcset.join(', ')}
					srcSet={srcset.join(', ')}
					data-sizes={sizesAttribute}
					data-lowsrc={lowQuality}
					src={fallback}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center center',
					}}
				 />
			</picture>
		</div>
	)
}

export default Image
