import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby';
// Import neccessary site components, try to maintain alphabetical order
import BlogFeed from 'components/blog';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class BlogFeedController extends Component {
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}blog/post/all/?_join=true&_sort=showDate&_order=ASC&_limit=4`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length) {
					this.setState({
						status: STATUS.LOADED,
						data: data,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allBlogPost && props.data.allBlogPost.edges.length) {
			props.data.allBlogPost.edges.forEach(post => {
				initialData.push(post.node);
			});
		}
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<BlogFeed data={this.state.data} />
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allBlogPost(sort: {order: DESC, fields: showDate}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, limit: 4) {
			    edges {
			      node {
			      	_model
			        id
			        photo
			        title
			        permalink
			        uri
			        intro
			        showDate
					type
			      }
			    }
			  }
			}
		`}
		render={data => (<BlogFeedController data={data} {...props} />)}
	/>
)