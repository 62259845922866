import React, { Component } from 'react'
import style from './events.module.scss';
import Slider from 'react-slick';
import parse from 'html-react-parser';

import Card from 'components/card/';
import Icon from 'components/icon/';
import Button from '../button/';
import Link from '../link';
import Arrow from './arrow';
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Events extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			leftDisabled: true,
			rightDisabled: false,
			opacity: "0"
		}
		this.node = null;
	}

	handleClick(direction) {
		if (direction === -1) this.slider.slickPrev();
		else this.slider.slickNext();
	}

	handleChange(slide) {
		const hasNext = (parseInt($(this.node).find('.slick-slide.slick-active').last().data('index')) < $(this.node).find('.slick-slide').length - 1 ? true : false);
		this.setState({
			leftDisabled: (slide === 0),
			rightDisabled: !hasNext,
		});
	}

	componentDidMount() {
		this.node = ReactDOM.findDOMNode(this);
	}

	render() {
		const slidesNumber = this.props.slides || 4;
		const slick = {
			dots: false,
			infinite: false,
			slidesToShow: slidesNumber,
			slidesToScroll: 1,
			className: style.slick,
			arrows: false,
			ref: slider => (this.slider = slider),
			afterChange: this.handleChange,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1.33,
						slidesToScroll: 1,
					}
				},
			],
		}
		const leftDisabled = this.state.leftDisabled;
		const rightDisabled = this.state.rightDisabled;
		const title = this.props.title || 'Local Events';
		const color = this.props.backgroundColor || 'ffffff';
		const styles = {
			backgroundColor: '#' + color,
		}
		const events = this.props.data;
		const viewAll = this.props.viewAll;
		return (
			<section className={style.events} style={styles} >
				<div className="grid-container">
					<div className="grid-x grid-margin-x" style={{ alignItems: "center" }}>
						<div className="cell small-10 medium-8">
							<div className={style.title}>{title}</div>
						</div>
						{events.length > slidesNumber && <div className={`${style.arrows} cell medium-offset-2 medium-2 show-for-medium`}>
							<Arrow direction="left" title="Go to previous slide of events." onClick={this.handleClick} disabled={leftDisabled} />
							<Arrow direction="right" title="Go to next slide of events." onClick={this.handleClick} disabled={rightDisabled} />
						</div>}
					</div>
					<div className="cell small-12">
						<Slider {...slick}>
							{events.map((event, index) => (
								<Card key={index} className="minHeight" data={event} type={event._model} />
							))}
						</Slider>
					</div>
					{viewAll && <div className="cell small-12">
						<div className={style.more}>
							<Link to="/events/" className="button primary">View All</Link>
						</div>
					</div>}
				</div>
			</section>
		)
	}
}

export default Events;