import React, { Component } from 'react';
import style from './textinput.module.scss';
import parse from 'html-react-parser';

export default class TextInput extends Component {

	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		this.state = {
			value: (this.props.field && this.props.field.value ? this.props.field.value : this.props.value),
		};
	}

	render() {
		if (this.props.focusState) {
			this.textInput.current.focus({preventScroll: true});
		}
		let { className } = this.props;
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		let onChange = (this.props.field && this.props.field.onChange ? this.props.field.onChange : this.props.onChange);
		let onBlur = (this.props.field && this.props.field.onBlur ? this.props.field.onBlur : this.props.onBlur);
		let onKeyDown = (this.props.field && this.props.field.onKeyDown ? this.props.field.onKeyDown : this.props.onKeyDown);
		let label = this.props.label;
		let maxLength = (this.props.maxLength ? this.props.maxLength : null);
		let pattern = (this.props.pattern ? this.props.pattern : null);
		let errorCondition = (this.props.form && this.props.form.touched[name] && this.props.form.errors[name] ? true : (this.props.errorCondition !== undefined ? this.props.errorCondition : false));
		let errorMessage = (this.props.form && this.props.form.errors[name] ? this.props.form.errors[name] : this.props.errorMessage);
		let autoComplete = (this.props.autoComplete !== undefined ? (this.props.autoComplete === 'off' || this.props.autoComplete === false ? 'off' : 'on') : 'on');
		className = (className||'').split(' ');
		className.push(style.container);
		className = className.filter((item) => item);
		return (
			<div className={className.join(' ')}>
				<label htmlFor={name}>{label}</label>
				<div className={style.fieldContainer}>
					{ (this.props.lines === undefined || this.props.lines === 1) && <input type="text" autoComplete={autoComplete} className={style.inputField} id={name} ref={this.textInput} placeholder={this.props.placeholder} name={name} value={this.props.value} onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown} maxLength={maxLength} pattern={pattern} /> }
					{ this.props.lines >= 2 && <textarea autoComplete={autoComplete} className={style.inputField} id={name} ref={this.textInput} placeholder={this.props.placeholder} rows={this.props.lines} name={name} value={this.props.value} onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown} maxLength={maxLength} pattern={pattern} /> }
					<div className={ [style.error, (errorCondition ? style.active : '')].join(' ') }>{(errorMessage) ? parse(errorMessage) : ''}</div>
				</div>
			</div>
		)
	}
}