import React, {Component} from 'react'
import { graphql, StaticQuery } from 'gatsby';
import style from './offcanvas.module.scss';
import Link from '../link';
import Icon from '../icon';
import Button from '../button';

import useLiveData from 'hooks/useLiveData';

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

class Offcanvas extends Component {

	handleDrilldown({ target }) {
		const parent = target.parentNode;
		const siblings = parent.childNodes;
		if (parent.classList.contains("js-drilldown-back")) {
			const activeMenus = document.querySelectorAll('.menu.vertical.nested.is-active');
			let currentMenu = activeMenus[activeMenus.length-1];
			currentMenu.classList.remove('is-active');
			currentMenu.classList.add('invisible');
		} else {
			siblings.forEach(element => {
				if (element.nodeName === "UL") {
					element.classList.remove('invisible');
					element.classList.add('is-active');
				}
			});
		}
	}

	componentDidMount() {
		if (canUseDOM) {
			const body = document.querySelector("body")
			if (!this.props.isOpen) body.style.overflow = null
		}
	}

	componentDidUpdate() {
		if (canUseDOM) {
			const body = document.querySelector("body")
			if (this.props.isOpen) {
				body.style.overflow = "hidden"
			} else {
				body.style.overflow = null
			}
		}
	}

	render() {
		const position = this.props.position||'right';
		let classes = [style.wrapper];
		if (this.props.isOpen)classes.push(style.isOpen);

		return (
			<div className={classes.join(' ')}>
				<div className={[style.menu, style[position]].join(' ')}>
					<div className={style.offCanvasTopBar}>
						<Button aria-label={"close"} className={style.close} onClick={this.props.offcanvasClose} ><Icon icon="close" width="32" height="32" /></Button>
					</div>
					<nav datalayer="Mobile Offcanvas Nav" id="offcanvasMenu" className={`${style.offCanvasWrapper}`}>
						<div className={`grid-container`}>
							<div className={`grid-x grid-padding-x`}>
								<div className={`cell small-12`}>
									<div className={`is-drilldown`}>
										<ul className={`vertical menu drilldown`}>
											{
												this.props.mainMenu.map((item, index) => {
													let { visible, target, href, text, children } = item;
													if (!href) href = '#';
													const targetAttr = target;

													if (visible) {
														if (text === "Services") {
															return (
																<li className={`is-drilldown-submenu-parent`} key={index}>
																	<Link className={ style.link } onClick={this.handleDrilldown.bind(this)} to={`#`}>Services</Link>
																	<ul className={`menu vertical nested submenu is-drilldown-submenu drilldown-submenu-cover-previous invisible`}>
																		<div>
																			<li className={`js-drilldown-back`}><Link onClick={this.handleDrilldown.bind(this)} href={`#`}>Back</Link></li>
																			<li><Link to={`/services/`} className={ style.link }>Services</Link></li>
																			{ this.props.services.length > 0 && this.props.services.map(({ title, permalink }, index) => <li key={`service-${index}`}><Link to={`/services/${permalink}/`} className={``}>{ title }</Link></li>  )}
																			<li><Link className={style.link} href="/portfolio/">Portfolio</Link></li>
																		</div>
																	</ul>
																</li>
															)
														} else if (children && children.length) {
															return (
																<li className={`is-drilldown-submenu-parent`} key={index}>
																	<Link className={ style.link } onClick={this.handleDrilldown.bind(this)} to={`#`}>{text}</Link>
																	<ul className={`menu vertical nested submenu is-drilldown-submenu drilldown-submenu-cover-previous invisible`}>
																		<div>
																			<li className={`js-drilldown-back`}><Link onClick={this.handleDrilldown.bind(this)} href={`#`}>Back</Link></li>
																			{href !== "#" && <li><Link target={targetAttr} to={href} className={ style.link }>{text}</Link></li>}
																			{ children.map((child, index) => {
																				const { visible, target, href, text } = child;
																				const targetAttr = target;

																				if (visible) {
																					if (href.indexOf("#") !== -1) {
																						return (
																							<li key={index}><a href={href} target={targetAttr} className={ style.link }>{text}</a></li>
																						)
																					}
																					return (
																						<li key={index}><Link to={href} target={targetAttr} className={ style.link }>{text}</Link></li>
																					)
																				}
																			})}
																		</div>
																	</ul>
																</li>
															)
														} else {
															return (
																<li key={index}>
																	<Link to={href} target={targetAttr} className={ style.link }>{text}</Link>
																</li>
															)
														}
													}
													return null;
												})
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</div>
				<div className={style.content}>
					{this.props.children}
				</div>
			</div>
		)
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query {
				allContentMenu(filter: {menuID: {eq: "main"}}) {
					edges {
						node {
							pages
						}
					}
				}
				allServicesService(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, sort: {fields: title, order: ASC}) {
					edges {
						node {
							permalink
							title
						}
					}
				}
			}
		`}
		render={data => {
			const { allServicesService, allContentMenu } = data;
			let services = [];
			if (allServicesService && allServicesService.edges.length) services = allServicesService.edges;
			if (services) services = services.map(({ node }) => node);

			const { edges: menuEdges } = allContentMenu;
			let mainMenu = menuEdges.map(({ node }) => node.pages)[0];
			const { statusText: providerStatusText, status: providerStatus, response: providerResponse } = useLiveData({
				type: 'ContentMenu',
				staticData: data,
			});
			if ((providerResponse.data.length > 0 || !!mainMenu !== false) && providerStatusText === "LOADED") {
				mainMenu = providerResponse.data.filter(datum => datum.menuID === "main")[0].pages;
			}
			return (
				<Offcanvas mainMenu={mainMenu} services={services} {...props} />
			)
		}}
	/>
)