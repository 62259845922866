import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

class ZLink extends Component {
	constructor(props) {
		super(props);
		this.buttonClick = this.buttonClick.bind(this);
		this.category = 'Internal Link';
	}
	static propTypes = {
		location: PropTypes.object.isRequired
	}
	buttonClick(e) {
		window.dataLayer = window.dataLayer || [];
		let linkLocation = e.currentTarget.parentNode.getAttribute("datalayer");
		var event = {};
		event.action = e.type;
		let href = this.props.to||this.props.href;
		event.link = href;
		event.page = this.props.location.pathname;
		event.category = this.category;
		if(typeof this.props.children === 'string') {
			event.text = this.props.children;
		}
		event.location = linkLocation;
		window.dataLayer.push(event);
	}
	render() {
		const scheme = /(?:https?:)?\/\/([^/]+)/i;
		const { location } = this.props;
		const trailingSlash = ('trailingSlash' in this.props ? this.props.trailingSlash : true);
		let href = this.props.to||this.props.href;
		if (!href) href = "";
		let passthrough = Object.assign({}, this.props);
		delete passthrough.location;
		delete passthrough.to;
		delete passthrough.href;
		if (passthrough.state === "") passthrough.state = null;
		let result;
		let external = ('external' in this.props ? this.props.external : false);
		if (!('external' in this.props) && (result = scheme.exec(href)) !== null && result[1] !== location.host) {
			external = true;
			this.category = 'Outbound Link';
		}
		const className = this.props.className||'';
		href = href.replace(/&amp;/g, '&');
		href = href.replace(/&{2,}/g, '&');
		if (external === false) {
			if (trailingSlash && !/\/$/.test(href) && !/^#/.test(href) && !/\.[a-z0-9]+$/.test(href) && (!!href && href.indexOf("userfiles") !== -1)) { // The third regex test ensures we don't add a trailing slash to a PDF or other file type
				href += '/';
			}
			if (href.indexOf('userfiles') === -1) {
				if (this.props.refresh === true) {
					return (
						<a onClick={this.buttonClick} className={className} href={href} {...passthrough}>{this.props.children}</a>
					)
				}
				if (this.props.target === '_blank') {
					return (
						<a onClick={this.buttonClick} className={className} href={href} target="_blank" rel="noreferrer" {...passthrough}>{this.props.children}</a>
					)
				}
				return (
					<Link onClick={this.buttonClick} className={className} to={href} {...passthrough}>{this.props.children}</Link>
				)
			}
		}
		return (
			<a onClick={this.buttonClick} className={className} href={ href } target="_blank" rel="noopener noreferrer" {...passthrough}>{this.props.children}</a>
		)
	}
}

export default props => (
	<Location>
		{locationProps => <ZLink location={locationProps.location} {...props}>{props.children}</ZLink>}
	</Location>
);

/*
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

class ZLink extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	}

	render() {
		const { location } = this.props;
		const href = this.props.to||this.props.href;
		let external = this.props.external||false;
		if (('external' in this.props) / *&& (result = scheme.exec(href)) !== null* /) {
			external = this.props.external;
			// Ideally, compare the origin captured in the regex to the current domain of the site. Not sure how to do that when there's no window environment.
		}
		const className = this.props.className||'';
		if (external === false) {
			return (
				<Link className={className} to={ href } {...this.props}>{ this.props.children }</Link>
			)
		}
		else {
			return (
				<a className={className} href={ href } target="_blank" rel="noopener noreferrer" {...this.props}>{ this.props.children }</a>
			)
		}
	}
}

export default props => (
	<Location>
		{locationProps => <ZLink {...locationProps} {...props} />}
	</Location>
);
*/