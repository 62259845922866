import React, {Component} from 'react';
import style from './storyCard.module.scss';

class StoryCard extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			content: this.props.data.object.problem,
			button: 'problem',
		}
		this.data = this.props.data.object;
	}

	handleClick(content, activity) {
		this.setState({
			content: content,
			button: activity,
		});
	}

	render() {
		
		var title = ''
		if(this.data.title) {
			title = this.data.title
		} else {
			title = 'Success Story'
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function(name){
				if (name in style)className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}
		return (
			<div datalayer="Story Card" className={ style.container }>
				<div className={ className.join(' ') }>
					<div className={style.title}>{title}</div>
					<div className={style.buttons}>
						<div role="presentation" onClick={() => this.handleClick(this.data.problem, 'problem')} onKeyUp={() => this.handleClick(this.data.problem, 'problem')} className={ ['primary', 'button', style.button, `${this.state.button === 'problem' ? style.active : ''}`].join(' ') }>Problem</div>
						<div role="presentation" onClick={() => this.handleClick(this.data.solution, 'solution')} onKeyUp={() => this.handleClick(this.data.solution, 'solution')} className={ ['primary', 'button', style.button, `${this.state.button === 'solution' ? style.active : ''}`].join(' ') }>Solution</div>
						<div role="presentation" onClick={() => this.handleClick(this.data.result, 'result')} onKeyUp={() => this.handleClick(this.data.result, 'result')} className={ ['primary', 'button', style.button, `${this.state.button === 'result' ? style.active : ''}`].join(' ') }>Result</div>
					</div>
					<div className={style.content}>
						{this.state.content}
					</div>
				</div>
			</div>
		)
	}
}

export default StoryCard;
