import React, {Component} from 'react';
import style from './portfolioCard.module.scss';
import Background from 'components/background';
import ReactCardFlip from 'react-card-flip';

class PortfolioCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFlipped: false,
		}
		this.data = this.props.data.object;
	}

	render() {
		
		var title = ''
		if(this.data.name) {
			title = this.data.name
		} else {
			title = 'Service Option'
		}

		var description = ''
		if(this.data.description) {
			description = this.data.description
		} else {
			description = ''
		}

		var image = ''
		if(this.data.photo) {
			image = this.data.photo
		} else {
			image = null;
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function(name){
				if (name in style)className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}
		return (
			<div datalayer="Portfolio Card" role="presentation" className={style.container} onMouseEnter={() => this.setState({ isFlipped: true })} onMouseLeave={() => this.setState({ isFlipped: false })}>
				<ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
					<div className={[style.card, style.front].join(' ')}>
						<div className={style.image}>
							<Background image={image} style={ {minHeight: '100%'} }>
							</Background>
						</div>
						<div className={style.gradient} />
						<div className={style.title}>{title}</div>
					</div>
					<div className={[style.card, style.back].join(' ')}>
						<div className={style.content}>
							<div className={style.title}>{title}</div>
							<div className={style.description} dangerouslySetInnerHTML={{ __html: (description ? description : '') }} />
						</div>
					</div>
				</ReactCardFlip>
			</div>
		)
	}
}

export default PortfolioCard;
