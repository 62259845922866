import React, {Component} from 'react';
import style from './trainingCard.module.scss';
import { Transition, animated } from 'react-spring/renderprops';

class TrainingCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: true,
			toggle: '+',
			index: 0,
		}
		this.data = this.props.data;
	}

	render() {
		var title = ''
		if(this.data.title) {
			title = this.data.title
		} else {
			title = 'Training'
		}

		var description = ''
		if(this.data.body) {
			description = this.data.body
		} else {
			description = ''
		}

		var label = ''
		if(this.data.label) {
			label = this.data.label
		} else {
			label = 'Day'
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function(name){
				if (name in style)className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}

		const pages = [
			styles => (
				<animated.div style={{...styles}} className={[style.card, style.front].join(' ')}>
					<div className={style.title}>{title}</div>
					<div className={style.numContainer}>
						<div className={style.number}>{number}<sup>{nth(number)}</sup></div>
						<div className={style.label}>{label}</div>
					</div>
				</animated.div>
			),
			styles => (
				<animated.div style={{...styles}} className={[style.card, style.back].join(' ')}>
					<div className={style.content}>
						<div className={style.title}>{number}<sup>{nth(number)}</sup> {label}</div>
						<div className={style.description} dangerouslySetInnerHTML={{ __html: (description ? description : '') }} />
					</div>
				</animated.div>
			)
		]

		const number = this.props.index + 1;
		const nth = function(d) {
			if (d > 3 & d < 21) return 'th';
			switch (d % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		}
		
		return (
			<div datalayer="Training Card" className={`${style.container} cell`}>
				<div role="presentation" className={style.containerInner} onClick={() => this.setState({index: this.state.index === 1 ? 0 : this.state.index + 1, toggle: this.state.toggle === '+' ? '-' : '+'})} onKeyUp={() => this.setState({index: this.state.index === 1 ? 0 : this.state.index + 1, toggle: this.state.toggle === '+' ? '-' : '+'})}>
					<Transition
						native
						items={this.state.index}
						from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
						enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
						leave={{ opacity: 0, transform: 'translate3d(-50%,0,0)' }}>
						{index => pages[index]}
					</Transition>
					<div className={style.toggle}>{this.state.toggle}</div>
				</div>
			</div>
		)
	}
}

export default TrainingCard;
