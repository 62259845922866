import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import Image from 'components/image';
import Link from 'components/link';
import Icon from 'components/icon';
import Share from 'components/share';

import style from './newsreports.module.scss';


class NewsReports extends Component {
	render() {
		let news = null;
		let latest = null;
		const metaData = this.props.metaData;
		const twitterHandle = metaData.twitterHandle;

		if (this.props.allPressRelease && this.props.allPressRelease.edges.length) news = this.props.allPressRelease.edges;
		if (this.props.Featured && this.props.Featured.edges.length) latest = this.props.Featured.edges;

		if (latest) latest = latest.map(({ node }) => node)[0];

		if (news) {
			news = news.map(({ node }) => node);
			if (latest) news.length = 9;
			if (!latest) latest = news.shift();
		}

		const headline = latest.headline ? latest.headline : "";
		let excerpt = latest.excerpt ? latest.excerpt : "";
		let photo = latest.photo ? latest.photo : "";
		let uri = latest.uri ? latest.uri : "";

		const maxLength = 37;
		if (excerpt) {
			var wordCount = excerpt.split(" ").length;
			excerpt = excerpt.split(" ").splice(0,maxLength).join(" ");
			if (wordCount > maxLength) {
				excerpt += '...';
			}
		}

		return (
			<section id={style.newsReports}>
				<div className="grid-container small-full">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<div className="grid-x grid-padding-x posRel">
								<div className={[style.floater, "cell small-12 large-4"].join(" ")}>
									<div className={style.floaterWrapper}>
										<h3>News Reports <Icon className={[style.arrow, "hide-for-large"].join(" ")} icon="chevronDown" height="40px" width="40px" /></h3>
										<ul>
											{ news.map(({ headline, permalink }) => <li key={permalink}><Link className={style.link} to={`/news/${permalink}/`}>{headline}</Link></li>) }
										</ul>
									</div>
								</div>
								<div className="cell large-8 small-12">
									<div className="grid-x grid-padding-x">
										<div className={[style.imageWrapper, "cell small-12"].join(" ")}>
											<img src={photo} className={style.image} alt={headline} />
										</div>
									</div>
									<div className="grid-x grid-padding-x">
										<div className="cell small-12 large-6">
											<div className={style.headline}>
												{headline}
											</div>
											<Share className={style.socialIconWrapper} iconClass={style.socialIcon} buttonClass={style.socialIconButton} socialConfig={{
												twitterHandle,
												config: {
													url: `${metaData.siteUrl}${uri}`,
													title: headline,
													summary: excerpt,
													source: metaData.siteUrl
												},
											}} />
										</div>
										<div className="cell small-12 large-6">
											<p className={style.excerpt}>
												{ parse(excerpt) }
												<br />
												<Link to={uri}>Read More</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query {
				allPressRelease(sort: {fields: showDate, order: DESC}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, limit: 10) {
					edges {
						node {
							excerpt
							headline
							body
							photo
							permalink
							uri
						}
					}
				}
				Featured: allPressRelease(sort: {fields: showDate, order: DESC}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}, featured: {eq: true}}, limit: 1) {
					edges {
						node {
							excerpt
							headline
							body
							photo
							permalink
							uri
							featured
						}
					}
				}
				site {
					siteMetadata {
						siteUrl
						twitterHandle
					}
				}
			}
		`}
		render={({ allPressRelease, site, Featured }) => (
			<NewsReports allPressRelease={allPressRelease} Featured={Featured} metaData={site.siteMetadata} {...props} />
		)}
	/>
)