import React, {Component} from 'react'
import style from './header.module.scss';
import Logo from '../logo';
import Nav from '../navigation/';
import Icon from '../icon.js';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			
		}
	}

	render() {
		let classes = [];
		classes.push(style.header);
		const spacer = ('spacer' in this.props ? this.props.spacer : true);
		return (
			<>
				<header className={ classes.join(' ') } >
					<div className={`${style.grid} grid-container`}>
						<div className="grid-x grid-margin-x align-middle">
							<div className="cell small-6 medium-3" style={{position:'relative', verticalAlign: 'middle', alignSelf: 'center'}}>
								<div datalayer="Primary Nav Logo" style={{ alignItems: "flex-start" }} className={style.logoContainer}>
									<Logo />
								</div>
							</div>
							<div className="cell small-6 medium-8">
								<div className={ [style.navigationContainer, 'show-for-medium'].join(' ') }>
									<Nav />
								</div>
								<div className={ [style.buttonContainer, 'hide-for-medium'].join(' ') }>
									<button className={ style.offcanvasToggle } onClick={this.props.offcanvasOpen} aria-label="Offcanvas navigation menu" aria-controls="offcanvasMenu" aria-expanded={this.props.offcanvasState}><Icon className={style.hamburger} icon="bars" width="32" height="32"/></button>
								</div>
							</div>
						</div>
					</div>
				</header>
				{ spacer && <div className={ style.spacer } /> }
			</>
		)
	}
}

export default Header;