import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { StaticQuery, graphql } from 'gatsby';
import Button from '../button/';
import style from './footer.module.scss';
import Link from '../link';
import Icon from '../icon';
import SectionCallout from '../sectioncallout';
import Events from '../events/';
import NewsReports from '../newsreports/';
import BlogFeedController from 'controllers/blog/feed'
import NewsFeedController from 'controllers/news/feed'

// Form Imports
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/form/textinput/';
import Select from 'components/form/select/';
import ReCAPTCHA from 'react-google-recaptcha';

class Footer extends Component {

	constructor(props) {
		super(props);
		this.recaptchaRef = React.createRef();
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.timerID = null;
		this.serviceOptions = [];
		if (props.data && props.data.allServicesService && props.data.allServicesService.edges.length) {
			props.data.allServicesService.edges.forEach(service => {
				this.serviceOptions.push(service.node.title);
			});
			this.serviceOptions.sort();
			this.serviceOptions.push('Other');
		}

		const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

		this.contactSchema = Yup.object().shape({
			firstName: Yup.string()
				.required('Please enter your first name.'),
			lastName: Yup.string()
				.required('Please enter your last name.'),
			company: Yup.string()
				.required('Please enter your company name.'),
			email: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email address.'),
			phone: Yup.string()
				.matches(phoneRegExp, 'Phone number is not valid')
				.required('Please enter your phone number.'),
			subject: Yup.string()
				.required('Please select a subject.'),
		});
		this.state = {
			recaptcha: '',
			recaptchaError: false,
			success: false,
			searchQuery: '',
		}
		this.initialValues = {
			firstName: '',
			lastName: '',
			company: '',
			email: '',
			phone: '',
			subject: '',
			env: 'SCHEDULE_FORM',
			mailto: 'email1',
		}
		this.searchQuery = {};
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	componentWillUnmount() {
		if (this.timerID !== null)clearTimeout(this.timerID);
		this.timerID = null;
	}

	componentDidMount() {
		if (this.props.searchQuery) {
			this.searchQuery = this.props.searchQuery;
		}
		this.setState({
			searchQuery: this.searchQuery,
		});
	}

	changeHandler(e) {
		let {options, name, value, selectedIndex} = e.target;
		if(this.searchQuery) {
			this.searchQuery = {...this.state.searchQuery, ...{[name]: value}}
			this.setState({searchQuery: this.searchQuery});
		} else {
			this.setState({
				searchQuery: {[name]: value},
			})
		}
	}

	async submitForm(values, {setSubmitting, resetForm}) {
		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, {recaptcha: this.state.recaptcha})),
		};
		await fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function(response){
				if (!response.ok){
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID)clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.timerID = null;
				}, 3000);

			})
			.catch(function(err){
				alert(err);
			})
			.finally(function(){
				setSubmitting(false);
				document.getElementById('scheduleForm').reset();
				resetForm();
			})
		;
		this.recaptchaRef.current.reset();
	}

	render() {
		let cleanEvents = [];
		let footerMenu = [];
		if (this.props.allEventsEvent) {
			const events = this.props.allEventsEvent;
			if (events.length > 0) {
				events.forEach((event, index) => {
					if (event.hasOwnProperty("node")) {
						cleanEvents.push(event.node);
					}
				})
			}
		}
		if (this.props.data.allContentMenu) {
			const menu = this.props.data.allContentMenu.edges;
			// split the footer menu into 2 equal length array
			const menuNodes = menu.map(({ node }) => node.pages)[0];
			const half = Math.ceil(menuNodes.length / 2);
			if (menuNodes.length > half) {
				footerMenu = [
					menuNodes.slice(0, half),
					menuNodes.slice(half, menuNodes.length)
				];
			} else {
				footerMenu = [menuNodes];
			}
		}
		const services = [{ image: "/userfiles/Foundation Forms/foundation_module_image1-01.jpeg", title: "Danos Foundation", preTitle: "", description: "The Danos Foundation impacts and improves the communities where we live and work by mirroring the Purpose and Values of Danos. Within the guidelines of the Foundation’s Purpose and Values, we leverage our resources of time, talent and money to support and promote involvement in activities and initiatives to help resolve community challenges and make a difference in the lives of people.", link: "/foundation/", linkText: "Get Involved" }];
		const options = [
			'Automation',
			'Coatings',
			'Construction',
			'Environmental',
			'Fabrication',
			'Materials Management',
			'Production Workforce',
			'Project Management',
			'Scaffolding',
			'Shorebase and Logistics',
			'Specialized Consultants',
			'Other'
		];

		return (
			<>
				{this.props.enableDanosFoundation ? <section id="danos-foundation">
					<div className="grid-container small-full">
						<SectionCallout data={services} hasLink={true} />
					</div>
				</section> : <div className="show-for-medium" style={{ marginBottom: "3rem" }}></div>}
				{this.props.enableBlogFeed && <BlogFeedController />}
				{this.props.enableReports && <NewsReports />}
				{this.props.enableReportsFeed && <NewsFeedController />}
				{ (this.props.enableEvents && cleanEvents.length > 0) && <Events data={cleanEvents} title="Scheduled Events" description="" backgroundColor="F4F4F4" />}
				{ this.props.enableFooterTabs && <><Tabs className={[style.footerTabs].join(" ")}>
					<TabList style={{ listStyle: "none", margin: "0" }}>
						<div className="grid-container text-center">
							<div className="grid-x grid-padding-x small-up-1 medium-up-3">
								<Tab className={[style.tab, "cell"].join(" ")} style={{ textAlign: "center" }}>
									<div className={style.tabHeader}>Join Our Team</div>
									<span>Apply Online</span>
								</Tab>
								<Tab className={[style.tab, "cell"].join(" ")} style={{ textAlign: "center" }}>
									<div className={style.tabHeader}>RSVP</div>
									<span>Safety Focus</span>
								</Tab>
								<Tab className={[style.tab, "cell"].join(" ")} style={{ textAlign: "center" }}>
									<div className={style.tabHeader}>Schedule</div>
									<span>Customer Lunch &amp; Learn</span>
								</Tab>
							</div>
						</div>
					</TabList>
					<TabPanel className={style.tabContentWrapper}>
						<div className={[style.tabContent, "grid-container text-center"].join(" ")}>
							<div className="grid-x grid-padding-x small-up-1 medium-up-3">
								{/* <div className={["cell", style.cell].join(" ")}>
									<Link to="https://eoil.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/requisitions">
										<h4>All Positions</h4>
										<div className={style.greenMe}>View all open positions.</div>
										<div className={style.link}>Apply <Icon width="14" className={style.linkArrow} icon="arrowWithStemRight" /></div>
									</Link>
								</div> */}
								<div className={["cell", style.cell, 'footer-cell-item'].join(" ")}>
									<Link to="https://field-english-danos.icims.com/jobs/search?mode=job&iis=Company+Website">
										<h4>Field Positions</h4>
										<div className={style.greenMe}>Explore our open positions.</div>
										<div className={style.link}>Apply <Icon width="14" className={style.linkArrow} icon="arrowWithStemRight" /></div>
									</Link>
								</div>
								<div className={["cell", style.cell, 'footer-cell-item'].join(" ")}>
									<Link to="https://field-spanish-danos.icims.com/jobs/search?mode=job&iis=Company+Website">
										<h4>Posiciones abiertas</h4>
										<div className={style.greenMe}>Vea nuestras posiciones abiertas.</div>
										<div className={style.link}>Aplicar <Icon width="14" className={style.linkArrow} icon="arrowWithStemRight" /></div>
									</Link>
								</div>
								<div className={["cell", style.cell, 'footer-cell-item'].join(" ")}>
									<Link to="https://staff-danos.icims.com/jobs/search?mode=job&iis=Company+Website">
										<h4>Staff Positions</h4>
										<div className={style.greenMe}>View open staff positions.</div>
										<div className={style.link}>Apply <Icon width="14" className={style.linkArrow} icon="arrowWithStemRight" /></div>
									</Link>
								</div>
							</div>
						</div>
						{/* <div className={style.footerExt}>
							<div className={["grid-container"].join(" ")}>
								<div className="grid-x grid-margin-x">
									<div className="cell small-12 text-center">
										<p><strong>Don’t see your dream job?</strong> Jump into one of our many talent pools and make a splash with your resume by <Link to="/contact-us/">connecting with us.</Link></p>
									</div>
								</div>
							</div>
						</div> */}
					</TabPanel>
					<TabPanel className={style.tabContentWrapper}>
						<div className={[style.tabContent, "grid-container text-center"].join(" ")}>
							<div className="grid-x grid-padding-x">
								<div className="cell"><p>To find out more about available safety trainings, check out our Events page</p><p><Link to="/events/" className="button">Events</Link></p></div>
							</div>
						</div>
					</TabPanel>
					<TabPanel className={style.tabContentWrapper}>
						<div className={[style.tabContent, "grid-container text-center"].join(" ")}>
							<div className="grid-x grid-padding-x">
								<div className="cell">
									<p><strong>Sign up, and let Danos treat you and a select team to a lunchtime presentation of our dependable services and strategic solutions.</strong></p>
									<p>Ready to learn more about all the ways Danos supports operations like yours? Schedule a lunch and learn by filling out the form below, and we’ll bring the experts, the information—and the food!</p>
									<div className={style.formSection}>
										<Formik
											initialValues={this.initialValues}
											validationSchema={this.contactSchema}
											onSubmit={this.submitForm}
										>
											{({ values, isSubmitting, handleReset }) => (
												<Form id="scheduleForm">
													<div className={`${style.formFields} grid-x grid-margin-x small-up-1 medium-up-2 ${this.state.success ? style.hide : 'showMe'}`}>
														<Field value={values.firstName} className={`${style.cell} cell`} placeholder="First Name" name="firstName" label="First Name *" component={TextInput}/>
														<Field value={values.lastName} className={`${style.cell} cell`} placeholder="Last Name" name="lastName" label="Last Name *" component={TextInput}/>
														<Field value={values.company} className={`${style.cell} cell`} placeholder="Company Name" name="company" label="Company Name *" component={TextInput}/>
														<Field value={values.email} className={`${style.cell} cell`} placeholder="Email Address" name="email" label="Email Address *" component={TextInput}/>
														<Field value={values.phone} className={`${style.cell} cell`} placeholder="Phone" name="phone" label="Phone Number *" component={TextInput}/>
														<Field value={values.subject} className={`${style.cell} cell`} name="subject" label="Subject *" options={this.serviceOptions} placeholder="-- Select --" component={Select}/>
														<div className={`${style.cell} cell`} style={{ marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} ref={this.recaptchaRef} /></div><div className={ [style.error, (this.state.recaptchaError ? style.active : '')].join(' ') }>You must complete the ReCAPTCHA</div></div>
													</div>
													<div className={`grid-x grid-margin-x ${this.state.success ? style.hide : 'showMe'}`}>
														<div className="cell text-center">
															<button className={`${style.submitButton} button`} type="submit" disabled={isSubmitting}>Submit</button>
														</div>
													</div>
													{ <div className={ [style.success, (this.state.success ? style.showSuccess : '')].join(' ') }>Thank you for scheduling with Danos. Someone will receive your message shortly.</div> }
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>
				</Tabs>
				<div className={style.footerExt}>
					<div className={["grid-container"].join(" ")}>
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 text-right">
								{/*<Button href="#" className={style.button} label="Brochure Download">Brochure Download</Button>*/}
							</div>
						</div>
					</div>
				</div></> }
				<footer>
					<div className={style.bottom}>
						<nav className={style.footerNav}>
							<div className="grid-container">
								<div className="grid-x grid-margin-x">
									{
										footerMenu.map((menu, index) => {
											return <div key={index} datalayer="Footer Links" className="cell small-12 medium-3">
												{
													menu.map((item, index) => {
														return <Link key={index} to={item.href}>{item.text}</Link>
													})
												}
											</div>
										})
									}
									<div className="cell small-12 medium-4">
										<div className={style.social}>
											<span style={{display: "block"}}>Sign Up For Our Newsletter</span>
											<Button className={`${style.newsletter}`} target="_blank" href="https://us6.list-manage.com/contact-form?u=83d6be8198e8e2a693fb19ad1&form_id=b7d6119e3f63bfebbe0d53274eb76911">Sign Up Now</Button>
											<span style={{display: "block"}}>Get Connected</span>
											<div className={style.socialIconLinks}>
												<Link className={style.socialIconLink} to="https://www.linkedin.com/company/danos/"><div className="socialIconWrapper"><Icon className="socialIcon grey" width="37px" height="37px" icon="linkedin" /></div></Link>
												<Link className={style.socialIconLink} to="http://twitter.com/#!/danos1947"><div className="socialIconWrapper"><Icon className="socialIcon grey" width="37px" height="37px" icon="x-twitter" /></div></Link>
												<Link className={style.socialIconLink} to="https://www.facebook.com/danos1947"><div className="socialIconWrapper"><Icon className="socialIcon grey" width="37px" height="37px" icon="facebook" /></div></Link>
												<Link className={style.socialIconLink} to="https://www.youtube.com/user/ProducingConfidence"><div className="socialIconWrapper"><Icon className="socialIcon grey" width="37px" height="37px" icon="youtube" /></div></Link>
												<Link className={style.socialIconLink} to="https://www.instagram.com/danos1947/"><div className="socialIconWrapper"><Icon className="socialIcon grey" width="37px" height="37px" icon="instagram" /></div></Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</nav>
						<div datalayer="Footer Privacy Policy" className={style.copyright}>&copy; Copyright {new Date().getFullYear()}, Danos. <Link to="/privacy-policy/">Privacy Policy / Terms of Use.</Link> All rights reserved.</div>
					</div>
				</footer>
			</>
		)
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
				allServicesService(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
					edges {
						node {
							title
						}
					}
				}
				allContentMenu(filter: {menuID: {eq: "footer"}}) {
					edges {
						node {
							pages
						}
					}
				}
			}
		`}
		render={data => (<Footer data={data} {...props} />)}
	/>
)