import React, {Component} from 'react'
import Card from 'components/card'
import Link from 'components/link'
import style from './blogfeed.module.scss'

class BlogFeed extends Component {

	constructor(props) {
		super(props);
		this.state = {
			posts: [],
		}
	}

	componentDidMount() {
		if (this.props.data) {
			let posts = this.props.data;
			posts.length = 4;
			this.setState({
				posts: posts,
			})
		}
	}

	render() {
		return (
			<div className={style.container}>
				<div className={["grid-container"].join(' ')}>
					<div className="grid-x">
						<div className={style.header}>
							<h3 className={style.title}>{this.props.title ? this.props.title : "The Pipeline Newsletter"}</h3>
							<Link className={style.allLink} to={this.props.backLink ? this.props.backLink : "/danos-pipeline/"}>{this.props.backText ? this.props.backText : "View all posts"}</Link>
						</div>
					</div>
					<div className="grid-x small-up-1 medium-up-2 large-up-4">
						{this.state.posts.map((card, index) => {
							return (
								<div key={index} className={`${style.cardHolder} cell`}>
									<Card type={card._model} data={card} title={card.title ? card.title : card.headline} image={card.photo ? card.photo : card.featuredImage} description={card.intro ? card.intro : card.excerpt} />
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}

export default BlogFeed;