import React, {Component} from 'react';
import style from './postCard.module.scss';
import Background from 'components/background';
import Link from 'components/link';
import Share from 'components/share';
import { graphql, StaticQuery } from 'gatsby';
import ClientOnly from 'components/ClientOnly';

class PostCard extends Component {

	render() {
		const metaData = this.props.metaData;
		const twitterHandle = metaData.twitterHandle;
		const data = this.props.data;
		var title = ''
		if(data.title) {
			title = data.title
		} else if (data.name) {
			title = data.name
		} else if (data.headline) {
			title = data.headline
		}
		var image = ''
		if(data.photo) {
			image = data.photo
		} else if (data.featuredImage) {
			image = data.featuredImage
		} else {
			image = null;
		}

		const link = `/${data._model === "Post" ? "danos-pipeline" : "news"}/${data.permalink}/`

		var description = '';
		if(data.intro) {
			description = data.intro
		} else if (data.description) {
			description = data.description
		} else if (data.excerpt) {
			description = data.excerpt
		}
		const maxLength = 12;
		if (description) {
			var wordCount = description.split(" ").length;
			description = description.split(" ").splice(0,maxLength).join(" ");
			if (wordCount > maxLength) {
				description += '...';
			}
		}

		let className = [style.card];
		if (this.props.className) {
			this.props.className.split(' ').forEach(function(name){
				if (name in style)className.push(style[name]);
				else className.push(name);
			});
			className.filter(x => x);
		}

		let date = new Date(data.showDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

		return (
			<div datalayer="Listing Card" className={ style.container }><Link className={style.linkContainer} href={link} aria-label={`Read more about ${title}`}><div className={ className.join(' ') }>
				<div className={style.image}>
					<Background className={style.background} blur="false" lowQuality={false} image={image} style={ {minHeight: '100%'} }>
					</Background>
				</div>
				<div className={style.title}>{title}</div>
				<div className={style.date}>{date}</div>
				{description && <div className={style.description}>{description}</div>}
				<div className={style.buttons}>
					<Share className={style.socialIcons} buttonClass={style.postButton} iconClass={style.socialButtonIcon} socialConfig={{
						twitterHandle,
						config: {
							url: `${metaData.siteUrl}${link}`,
							title,
						},
					}} />
				</div>
			</div></Link></div>
		)
	}
}

export default props => (
  <StaticQuery
	query={graphql`
		query {
			site {
				siteMetadata {
					siteUrl
					twitterHandle
				}
			}
		}
	`}
	render={(data) => (
	  <PostCard metaData={data.site.siteMetadata} {...props} />
	)}
  />
)
