import React, { Component } from 'react'
import { buildURL } from 'react-imgix';
import styles from './background.module.scss';
// import ClientOnly from './ClientOnly';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import '../styles/blurup.scss';

if (typeof document !== 'undefined' && window.__lazyBGLOADINIT === undefined) {
	const bgLoad = function (url, cb){
		var imgURL = /url\('(.*?)'\)/;
		if (imgURL.test(url)) {
			var match = imgURL.exec(url);
			url = match[1];
		}
		var img = document.createElement('img');
		img.onload = function(){
			img.onload = null;
			img.onerror = null;
			img = null;
			cb();
		};
		img.onerror = img.onload;
		img.src = url;
		if(img && img.complete && img.onload){
			img.onload();
		}
	};

	document.addEventListener('lazybeforeunveil', function(evt){
		const target = evt.target;
		const background = target.getAttribute('data-background');
		if (background) {
			evt.detail.firesLoad = true;
			const load = (function(){
				return function(){
					target.style.backgroundImage = background;
					evt.detail.firesLoad = false;
					window.lazySizes.fire(target, '_lazyloaded', {}, true, true);
				}
			})(background);
			bgLoad(background, load);
		}
	});
	window.__lazyBGLOADINIT = true;
}

const domain = 'https://danos.imgix.net';
class Background extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
		}
	}

	render() {
		if (typeof window === 'undefined' && this.props.clientOnly === true)return null;
		let { image, width, overlay, className, lowQuality } = this.props;
		if (lowQuality === undefined)lowQuality = true;
		let imagePath = (image ? image.replace(/^\//, '') : 'images/Danos-Default-Placeholder-v2.jpg');
		let lowQualityImage;
		if (imagePath.indexOf('/') === -1)imagePath = `images/${imagePath}`;
		imagePath = `${domain}/${imagePath}`;
		width = width||1920;
		imagePath = buildURL(imagePath, {
			'max-w': width,
			auto: 'compress',
		});
		const hasURL = /^url\(/;
		if (hasURL.test(imagePath) === false)imagePath = `url('${imagePath}')`;
		if (lowQuality === true) {
			lowQualityImage = buildURL(imagePath, {
				w: width*.05,
				blur: 20,
			});
			if (hasURL.test(lowQualityImage) === false)lowQualityImage = `url('${lowQualityImage}')`;
		}
		if (overlay) {
			imagePath = `${overlay}, ${imagePath}`;
			if (lowQuality) {
				lowQualityImage = `${overlay}, ${lowQualityImage}`;
			}
		}
		className = (className||'').split(' ');
		className.push(styles.container);
		if (lowQuality) {
			className.push('lazyload');
		}
		className = className.filter((item) => item);
		/*
		const match = useMemo(() => (
			data.allFile.nodes.find(({ relativePath }) => imagePath === relativePath)
		), [ data, imagePath ]);
		const placeholder = useMemo(() => (
			data.allFile.nodes.find(({ relativePath }) => 'Explore-Brookhaven-Georgia-Placeholder-Icon.jpg' === relativePath)
		), [ data ]);
		const imageData = [match ? match.childImageSharp.fluid : placeholder.childImageSharp.fluid];
		if (overlay) {
			imageData.push(overlay);
		}
		return (
			<BackgroundImage
				Tag="div"
				className={className}
				fluid={imageData.reverse()}
				{...this.props}
			>{this.props.children}</BackgroundImage>
		)
		*/
		let passThrough = Object.assign({}, this.props);
		let style = Object.assign({}, passThrough.style, {
			width: '100%',
			minHeight: '100%',
			opacity: '.99',
			position: 'relative',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundImage: imagePath,
		});
		if (lowQuality) {
			style.backgroundImage = lowQualityImage;
			// style['--lowquality'] = lowQualityImage;
			passThrough['data-background'] = imagePath;
		}
		delete passThrough.image;
		delete passThrough.width;
		delete passThrough.overlay;
		delete passThrough.children;
		delete passThrough.style;
		delete passThrough.className;
		delete passThrough.lowQuality;
		delete passThrough.clientOnly;
		return (
			<>
				<div
					style={style}
					className={className.join(' ')}
					{...passThrough}
				 >{this.props.children}</div>
			</>
		)
	}
}

export default Background