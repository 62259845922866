import React, { Component } from 'react'
import style from './sectioncallout.module.scss';
import Arrow from '../events/arrow';
import Button from '../button';
import { CSSTransitionGroup } from 'react-transition-group';
import Background from '../../components/background';


export default class SectionCallout extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			currentIndex: 0,
		}
	}

	handleClick(e) {
		let val = this.state.currentIndex + e;
		if (this.props.data[`${val}`]) {
			this.setState({ currentIndex: val })
		}
		if (val < 0) {
			this.setState({ currentIndex: this.props.data.length - 1 })
		}
		if (val === this.props.data.length) {
			this.setState({ currentIndex: 0 })
		}
	}

	render() {
		const currentIndex = this.state.currentIndex;
		const data = this.props.data[currentIndex];
		var image = 'Danos-Default-Placeholder-v2.jpg';
		if (data.image) {
			image = data.image;
		} else if (data.photo) {
			image = data.photo;
		}
		const preTitle = data.preTitle;
		const title = data.title;
		// const description = data.description;
		const link = data.link;
		const linkText = data.linkText;

		let propStyle = {};

		if (this.props.minHeightNum) propStyle.minHeight = (this.props.minHeightNum + (this.props.arrows ? 65 : 0)) + "px";

		var description = '';
		if (data.description) {
			description = data.description
		} else {
			description = '';
		}
		const maxLength = 37;
		if (description && this.props.limitWords) {
			var wordCount = description.split(" ").length;
			description = description.split(" ").splice(0,maxLength).join(" ");
			if (wordCount > maxLength) {
				description += '...';
			}
		}
		return (
			<div className="grid-x grid-margin-x small-margin-collapse posRel">
				<div className={`${style.imageCell} cell small-12 medium-${this.props.flip ? "10 medium-offset-1" : "9"}`}>
					<CSSTransitionGroup
						transitionName={{
							enter: style.imageEnter,
							enterActive: style.imageEnterActive,
							leave: style.imageLeave,
							leaveActive: style.imageLeaveActive,
						}}
						transitionEnterTimeout={500}
						transitionLeaveTimeout={500}>
						<div key={this.state.currentIndex} className={style.imageWrapper}>
							<Background className={style.background} image={image} />
						</div>
					</CSSTransitionGroup>
				</div>
				<div style={propStyle} className={[style.floater, "cell small-12 medium-7", (this.props.hasLink ? style.alt : ""), (this.props.flip ? style.flip : "")].join(" ")}>
					<CSSTransitionGroup
					transitionName={{
						enter: style.serviceDataEnter,
						enterActive: style.serviceDataEnterActive,
						leave: style.serviceDataLeave,
						leaveActive: style.serviceDataLeaveActive,
					}}
					transitionEnterTimeout={500}
					transitionLeaveTimeout={500}>
						<div key={this.state.currentIndex} className={style.floaterInner}>
						<div className={style.preTitle}>{preTitle}</div>
						<div className={this.props.hasLink ? [style.title, style.alt].join(" ") : style.title}>{title}</div>
						<div className={this.props.hasLink ? [style.description, style.descAlt].join(" ") : style.description}>{description}</div>
						{this.props.hasCTA && <Button href={link}>{linkText ? linkText : "Learn More"}</Button>}
						{/*<div style={{ width: "133px", height: "133px" }}>
							{ data.icon ? <div className={style.icon}><Image style={{ width: "133px", marginBottom: "35px" }} src={data.icon} alt={title} /></div> : <div style={{ marginBottom: "90px" }}></div> }
						</div>*/}
						</div>
					</CSSTransitionGroup>
					{this.props.arrows && <div className={[style.floatDown, "cell small-12"].join(" ")}>
						{this.props.data.length > currentIndex && <div className={`${style.arrows} cell small-6`}>
							<Arrow direction="left" title="Go to previous content block." onClick={this.handleClick} onKeyUp={this.handleClick} />
							<Arrow direction="right" title="Go to next content block." onClick={this.handleClick} onKeyUp={this.handleClick} />
						</div>}
					</div>}
					{!this.props.arrows && this.props.hasLink ? <div className={[style.floatDown, style.hasLink, "cell small-12"].join(" ")}>
						<Button href={link}>{linkText ? linkText : "Learn More"}</Button>
					</div> : "" }
				</div>
			</div>
		)
	}
}