import React, {Component} from 'react';
import style from './plus.module.scss';
import ReactDOM from 'react-dom';
import Icon from '../icon';

class Plus extends Component {

	componentDidMount() {
		const node = ReactDOM.findDOMNode(this);
		const onClick = this.props.onClick;
		node.addEventListener('click', function(evt){
			onClick();
		});
	}

	render() {
		let className = [style.container];
		if (this.props.disabled)className.push(style.disabled);
		return (
			<div className={ className.join(' ') } ><Icon icon="plus-w-circle" title={this.props.title} className={ [style.plusButton, 'eventPlusButton'].join(' ') } /></div>
		)
	}
}

export default Plus;
